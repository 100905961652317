import { sprintf as originalSprintf } from "sprintf-js";
import { Translations } from "./types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sprintf(
  str: string,
  ...args: TranslationReplacementArgs[]
): string {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return originalSprintf(str, ...args);
  } catch (e) {
    // @ts-ignore
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.error("i18n", e);
    }

    // return string if sprintf failed
    // It is better to display unformatted string than break js at all
    return str;
  }
}

const phraseCookie = "phraseapp_translated_scope=1";

function simpleTranslate(messages: Translations): TranslateFunc {
  return (key = "", ...rest: TranslationReplacementArgs[]) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    sprintf(messages[key] || key, ...rest);
}

function phraseIceTranslate(messages: Translations): TranslateFunc {
  return (key = "") => {
    if (messages[key]) {
      const escapedKey = key
        .replace(/</g, "[[[[[[html_open]]]]]]")
        .replace(/>/g, "[[[[[[html_close]]]]]]");

      return `[[__phrase_${escapedKey}__]]`;
    }

    return key;
  };
}

export type TranslationReplacementArgs = any;
export type TranslatedContent = string;
export type TranslateFunc = (
  key: string,
  ...rest: TranslationReplacementArgs[]
) => TranslatedContent;

const isBrowser = () => typeof globalThis.document !== "undefined";

declare global {
  // eslint-disable-next-line vars-on-top, no-var
  var PHRASEAPP_CONFIG: unknown;
}

export function createTranslate(messages: Translations) {
  let translate: TranslateFunc = simpleTranslate(messages);

  if (isBrowser()) {
    const phraseIceEnabled = globalThis.document.cookie.includes(phraseCookie);

    if (phraseIceEnabled) {
      translate = phraseIceTranslate(messages);

      globalThis.PHRASEAPP_CONFIG = {
        projectId: "f45dff8f28c303b9d8a8c4affe7b9e45",
        fullReparse: true,
        prefix: "[[__",
        suffix: "__]]",
        autoLowercase: false,
      };

      const phraseapp = globalThis.document.createElement("script");
      phraseapp.type = "text/javascript";
      phraseapp.async = true;
      phraseapp.src = [
        "https://",
        "phraseapp.com/assets/in-context-editor/2.0/app.js?",
        new Date().getTime(),
      ].join("");
      const firstScript = globalThis.document.getElementsByTagName("script")[0];
      firstScript?.parentNode?.insertBefore(phraseapp, firstScript);
    }
  }

  return { T: translate };
}
