/* eslint-disable react/state-in-constructor, react/destructuring-assignment */
import { NavLink } from 'apps/freshbuffer/src/core/Routing/NavLink';
import React from 'react';

import { Typography } from '@mui/material';
import { Locale, TranslateFunc } from 'apps/freshbuffer/src/core/Intl/types';
import { reportError } from '.';

import { ROUTES } from '../Routing';
import styles from './ErrorBoundary.module.css';

export interface ErrorBoundaryProps {
  children: React.ReactNode;
  locale: Locale;
  T: TranslateFunc;
}

export interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  override state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  override componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    reportError(error, {
      extra: { context: 'ErrorBoundary', metaData: errorInfo },
    });
  }

  override render() {
    if (this.state.hasError) {
      return (
        <div className={styles.root}>
          <NavLink.Anchor
            href={ROUTES.Home.url({}, this.props.locale)}
            className={styles.logoContainer}
          >
          </NavLink.Anchor>
          <div className={styles.spacer} />
          <div className={styles.content}>
            <Typography variant="h4" className={styles.title}>
              {this.props.T('500_error_page.heading.oops')}
            </Typography>
            <Typography className={styles.paragraph} variant="body2">
              {this.props.T('500_error_page.body.tech_difficulties')}
            </Typography>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
