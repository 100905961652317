import { DeviceType } from "@freshbuffer/utilities/UserAgent/types";
import { DesignTokens } from "./theme.design";

export function getSSRScreenWidth(deviceType: DeviceType) {
  switch (deviceType) {
    case "mobile":
      return DesignTokens.breakpoint.sm;
    case "tablet":
      return DesignTokens.breakpoint.md;
    case "desktop":
      return DesignTokens.breakpoint.lg;
    default:
      return DesignTokens.breakpoint.md;
  }
}
