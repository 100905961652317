import memoize from "lodash-es/memoize";
import { CurrencyFormatOptions, Locale } from "./types";

export const NumberFormat = {} as Record<
  "DEFAULT" | "NO_FRACTION" | "NO_FRACTION_NO_GROUPING",
  Intl.NumberFormatOptions
>;

NumberFormat.DEFAULT = {};

NumberFormat.NO_FRACTION = {
  ...NumberFormat.DEFAULT,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

NumberFormat.NO_FRACTION_NO_GROUPING = {
  ...NumberFormat.NO_FRACTION,
  useGrouping: false,
};

export const CurrencyFormat = {} as Record<
  | "DEFAULT"
  | "DEFAULT_NO_FRACTION"
  | "WITH_CURRENCY_CODE"
  | "WITH_CURRENCY_SYMBOL"
  | "WITH_CURRENCY_CODE_NO_FRACTION"
  | "WITH_CURRENCY_CODE_NO_FRACTION_NO_GROUPING"
  | "WITH_CURRENCY_SYMBOL_NO_FRACTION"
  | "WITH_CURRENCY_CODE_DOUBLE_FRACTION"
  | "WITH_CURRENCY_SYMBOL_NO_FRACTION_NO_GROUPING"
  | "WITH_CURRENCY_SYMBOL_DOUBLE_FRACTION",
  CurrencyFormatOptions
>;

CurrencyFormat.DEFAULT = {
  style: "currency",
  currencyDisplay: "code",
  withCurrency: false,
};

CurrencyFormat.DEFAULT_NO_FRACTION = {
  ...CurrencyFormat.DEFAULT,
  ...NumberFormat.NO_FRACTION,
};

/* Recommended presets */

CurrencyFormat.WITH_CURRENCY_CODE = {
  ...CurrencyFormat.DEFAULT,
  currencyDisplay: "code",
  withCurrency: true,
};

CurrencyFormat.WITH_CURRENCY_SYMBOL = {
  ...CurrencyFormat.DEFAULT,
  currencyDisplay: "symbol",
  withCurrency: true,
};

CurrencyFormat.WITH_CURRENCY_CODE_NO_FRACTION = {
  ...NumberFormat.NO_FRACTION,
  ...CurrencyFormat.WITH_CURRENCY_CODE,
};

CurrencyFormat.WITH_CURRENCY_CODE_NO_FRACTION_NO_GROUPING = {
  ...NumberFormat.NO_FRACTION_NO_GROUPING,
  ...CurrencyFormat.WITH_CURRENCY_CODE,
};

CurrencyFormat.WITH_CURRENCY_SYMBOL_NO_FRACTION = {
  ...NumberFormat.NO_FRACTION,
  ...CurrencyFormat.WITH_CURRENCY_SYMBOL,
};

CurrencyFormat.WITH_CURRENCY_SYMBOL_NO_FRACTION_NO_GROUPING = {
  ...NumberFormat.NO_FRACTION_NO_GROUPING,
  ...CurrencyFormat.WITH_CURRENCY_SYMBOL,
};

/* Custom purpose-made presets  */

CurrencyFormat.WITH_CURRENCY_CODE_DOUBLE_FRACTION = {
  ...CurrencyFormat.DEFAULT,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  withCurrency: true,
};

CurrencyFormat.WITH_CURRENCY_SYMBOL_DOUBLE_FRACTION = {
  ...CurrencyFormat.WITH_CURRENCY_SYMBOL,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: false,
};

const UnitFormat: Record<string, Intl.NumberFormatOptions> = {};

UnitFormat.DEFAULT = {
  style: "unit",
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  unitDisplay: "long",
};

export function createNumberFormat(locale: Locale) {
  const getFormatter = memoize(
    (options: Intl.NumberFormatOptions) =>
      new Intl.NumberFormat(locale, options),
    (options) => JSON.stringify(options, Object.keys(options).sort())
  );

  function formatNumber(value: number, options?: Intl.NumberFormatOptions) {
    return getFormatter(options || NumberFormat.DEFAULT).format(value);
  }

  return {
    formatNumber,
  };
}
