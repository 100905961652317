interface IApiClientError<DataType extends object = object> {
  message: string;
  code: string;
  data?: DataType;
  response?: Response;
}

export class ApiClientError<DataType extends object = object>
  extends Error
  implements IApiClientError<DataType>
{
  override name = 'ApiClientError';

  code!: IApiClientError['code'];

  data!: IApiClientError<DataType>['data'];

  url: string;

  status: number;

  response: IApiClientError['response'];

  constructor({ message, code, data, response }: IApiClientError<DataType>) {
    super(message);
    this.code = code;
    this.data = data;
    this.url = response?.url || '';
    this.status = response?.status || 0;
    this.response = response;
  }

  toJSON() {
    return {
      message: this.message,
      code: this.code,
      data: this.data,
    };
  }
}
