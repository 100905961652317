export const DeviceTypes = [
  'mobile',
  'desktop',
  'console',
  'tablet',
  'smarttv',
  'wearable',
  'embedded',
] as const;

export type DeviceType = (typeof DeviceTypes)[number];
