import { createTheme } from '@mui/material/styles';
import { match } from 'css-mediaquery';
import { Locale } from 'apps/freshbuffer/src/core/Intl/types';
import { MuiLocalesMap } from 'apps/freshbuffer/src/core/MUI/MuiLocalesMap';
import { DeviceType } from 'apps/freshbuffer/src/utilities/UserAgent/types';
import { MuiThemeConfig } from './theme.mui-config';
import { getSSRScreenWidth } from './getSSRScreenWidth';

export function makeTheme(locale: Locale, deviceType: DeviceType) {
  const ssrMatchMedia = (query: string) => ({
    matches: match(query, {
      // The estimated CSS width of the browser.
      width: getSSRScreenWidth(deviceType),
    }),
  });

  const extendedThemeConfig = {
    ...MuiThemeConfig,
    components: {
      ...MuiThemeConfig.components,
      MuiUseMediaQuery: {
        defaultProps: {
          ssrMatchMedia,
        },
      },
    },
  };

  const value = createTheme(extendedThemeConfig, MuiLocalesMap[locale]!);

  return value;
}
