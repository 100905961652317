import { ApiClientBase } from './ApiClientBase';

export class APIClientExtension implements ApiClientBase {
  base: ApiClientBase;

  constructor(base: ApiClientBase) {
    this.base = base;
  }

  get freshbufferWebOrigin() {
    return this.base.freshbufferWebOrigin;
  }

  get freshbufferApiOrigin() {
    return this.base.freshbufferApiOrigin;
  }

  get headers() {
    return this.base.headers;
  }

  withHeader(name: string, value: string) {
    this.base.withHeader(name, value);
    return this;
  }

  withCookies(cookies: Record<string, string | null>) {
    this.base.withCookies(cookies);
    return this;
  }

  get authorizationHeaderGetter() {
    return this.base.authorizationHeaderGetter;
  }

  withAuthorizationHeaderGetter(
    authorizationHeaderGetter?: () => string | undefined,
  ): void {
    this.base.withAuthorizationHeaderGetter(authorizationHeaderGetter);
  }

  get handleResponse() {
    return this.base.handleResponse;
  }
}
