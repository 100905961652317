const headerFontFamily = [
  '"Open Sans"',
  "Helvetica",
  "Arial",
  "sans-serif",
].join(", ");

const bodyFontFamily = ['"Open Sans"', "Helvetica", "Arial", "sans-serif"].join(
  ", "
);

const common = {
  black: "#000000",
  white: "#FFF",
  transparentGray: "rgba(255, 255, 255, 0.2)",
};

const text = {
  primary: "#3A3A3C",
  secondary: "#989898",
};

const primary = {
  contrastText: "#FFF",
  dark: "#D1D1D6",
  main: "#F5F5F7",
  light: "#EBEBF0",
};

const secondary = {
  contrastText: "#FFF",
  dark: "#1C1C1E",
  main: "#3A3A3C",
  light: "#7C7C80",
};

const tertiary = {
  contrastText: "#FFF",
  dark: "#FF9F0A",
  main: "#FF9500",
  light: "#FFD873",
};

const success = {
  contrastText: "#FFF",
  dark: "#30D158",
  main: "#34C759",
  light: "#3EEE74",
};

const action = {
  contrastText: "#FFF",
  dark: "#006EDB",
  main: "#0071E3",
  light: "#0077ED",
};

const danger = {
  contrastText: "#FFF",
  dark: "#AC0400",
  main: "#CB302C",
  light: "#E94944",
};

const warning = {
  contrastText: "#FFF",
  dark: "#C75300",
  main: "#E86108",
  light: "#FC7D21",
};

const mono = {
  contrastText: "#FFF",
  dark: "#CEDBE3",
  main: "#E2E9EE",
  light: "#F2F7FB",
};

const neutral = {
  contrastText: "#FFF",
  main: "#000000",
  overlay: "rgba(2, 15, 40, 0.5)",
  gray: "#99A3AC",
  light: "#FFFFFF",
};

const breakpoint = {
  xs: 0,
  sm: 600,
  md: 1024,
  lg: 1360,
  xl: 1680,
};

const borderRadius = {
  xs: "4px",
  sm: "8px",
  md: "16px",
  lg: "24px",
  xl: "32px",
};

const fontSize = {
  sm: "14px",
  md: "24px",
  lg: "40px",
};

export const DesignTokens = {
  bodyFontFamily,
  headerFontFamily,
  color: {
    common,
    text,
    primary,
    secondary,
    tertiary,
    success,
    danger,
    warning,
    mono,
    neutral,
    action,
  },
  breakpoint,
  borderRadius,
  fontSize,
};
