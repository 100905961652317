import { GeneralErrorCodeMessages } from "apps/freshbuffer/src/core/Errors/message-maps";
import { GeneralErrorCode } from "apps/freshbuffer/src/core/Errors/types";
import { ApiClientError } from "./ApiClientError";

export async function handleResponse<T = any>(
  fetchPromise: Promise<Response>,
  CustomApiClientError: typeof ApiClientError = ApiClientError
): Promise<{
  response: Response;
  data: T;
}> {
  let response: Response | null = null;

  try {
    response = await fetchPromise;

    let contentPromise: Promise<any>;

    const contentType = response.headers.get("Content-Type") || null;

    if (contentType === null) {
      contentPromise = Promise.resolve({});
    } else if (contentType.startsWith("application/json")) {
      contentPromise = response.json();
    } else if (contentType.startsWith("text/")) {
      contentPromise = response.text().then((message) => ({ message }));
    } else {
      contentPromise = Promise.resolve({});
    }

    const body = (await contentPromise) || {};

    if (!response.ok) {
      // eslint-disable-next-line prefer-const
      let { code, message, ...data } = body as {
        code?: string;
        message?: string;
      } & any;

      // handle validation errors from freshbuffer APi
      if (
        Array.isArray(data.errors) &&
        data.errors &&
        data.errors.length &&
        data.errors[0]?.code &&
        (!message || !code)
      ) {
        message =
          message || GeneralErrorCodeMessages[GeneralErrorCode.INVALID_REQUEST];
        code = code || GeneralErrorCode.INVALID_REQUEST;
      }

      message =
        message ||
        GeneralErrorCodeMessages[
          `http_${response.status}` as GeneralErrorCode
        ] ||
        response.statusText;
      code = code || `http_${response.status}`;

      throw new CustomApiClientError({
        message,
        code,
        data,
        response,
      });
    }

    return { response, data: body };
  } catch (error) {
    if (error instanceof CustomApiClientError) {
      throw error;
    } else {
      const err = error as Error & { code?: string };
      const message: string =
        // eslint-disable-next-line custom/no-restricted-error-message
        err.message || GeneralErrorCodeMessages[GeneralErrorCode.UNKNOWN];
      const code: string = err.code || GeneralErrorCode.UNKNOWN;

      throw new CustomApiClientError({ code, message });
    }
  }
}

export class ApiClientBase {
  static HEADER_CONTENT_TYPE_JSON: HeadersInit = Object.freeze({
    "Content-Type": "application/json",
  });

  freshbufferWebOrigin: string;

  freshbufferApiOrigin: string;

  headers: Record<string, string> = {};

  authorizationHeaderGetter?: () => string | undefined = undefined;

  constructor({
    freshbufferWebOrigin,
    freshbufferApiOrigin,
  }: {
    freshbufferWebOrigin: string;
    freshbufferApiOrigin: string;
  }) {
    if (!freshbufferWebOrigin) {
      throw new Error('"freshbufferWebOrigin" is required');
    }
    this.freshbufferWebOrigin = freshbufferWebOrigin;

    if (!freshbufferApiOrigin) {
      throw new Error('"freshbufferApiOrigin" is required');
    }
    this.freshbufferApiOrigin = freshbufferApiOrigin;
  }

  withHeader(name: string, value: string) {
    this.headers[name] = value;
    return this;
  }

  withCookies(cookies: Record<string, string | null>) {
    this.headers.Cookie = Object.entries(cookies)
      .map(([name, value]) => `${name}=${value ?? ""}`)
      .join(";");
    return this;
  }

  withAuthorizationHeaderGetter(
    authorizationHeaderGetter?: () => string | undefined
  ) {
    this.authorizationHeaderGetter = authorizationHeaderGetter;
  }

  handleResponse = handleResponse;
}
