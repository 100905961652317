import { Locale } from "apps/freshbuffer/src/core/Intl/types";
import { AppRoute, AppRouteParams } from "./types";

export function defineRoute<TParams extends AppRouteParams>(
  pathname: string,
  nlPathname?: string,
  isLandlordRoute?: boolean
): AppRoute<TParams> {
  const pathnameByLocale: Record<Locale, string> = {
    [Locale.en]: pathname,
    [Locale.nl]: nlPathname || pathname,
  };

  return Object.freeze({
    pathname,
    pathnameByLocale,
    isLandlordRoute: isLandlordRoute ?? false,

    url(params, locale, localeSerialization) {
      let url = locale ? pathnameByLocale[locale] || pathname : pathname;

      const urlSearchParams = new URLSearchParams();

      Object.entries(params).forEach(([key, val]) => {
        if (url.includes(`[${key}]`)) {
          url = url.replace(`[${key}]`, val);
        } else {
          urlSearchParams.append(key, val);
        }
      });

      if (localeSerialization === "query") {
        urlSearchParams.append("locale", locale);
      }

      const urlSearchParamsString = urlSearchParams.toString();

      return (
        (localeSerialization === "prefix" && locale !== Locale.nl
          ? `/${locale}`
          : "") +
        url +
        (urlSearchParamsString ? `?${urlSearchParamsString}` : "")
      );
    },

    urlObject(params, locale) {
      return {
        pathname: locale ? pathnameByLocale[locale] || pathname : pathname,
        params,
      };
    },
  } as AppRoute<TParams>);
}
