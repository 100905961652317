import 'apps/freshbuffer/src/bootstrap';

import { CacheProvider } from '@emotion/react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Locale } from 'apps/freshbuffer/src/core/Intl/types';
import { createMuiDateLocalizationAdapter } from 'apps/freshbuffer/src/core/MUI/MuiDateLocalizationAdapter';
import NextApp, {
  AppContext as NextAppContext,
  AppProps as NextAppProps,
  NextWebVitalsMetric,
} from 'next/app';
import Head from 'next/head';
import React from 'react';

import { IntlProvider, createIntl } from 'apps/freshbuffer/src/core/Intl';

import { EmotionCache } from '@emotion/cache';
import { CommonMessages } from 'apps/freshbuffer/src/core/CommonMessages';
import { makeTheme } from 'apps/freshbuffer/src/styles/theme.mui';

import { Translations } from '@freshbuffer/intl';
import 'apps/freshbuffer/src/styles/GlobalStyles.css';
import 'apps/freshbuffer/src/styles/normalize.css';
import 'apps/freshbuffer/src/styles/theme.design.css';
import 'apps/freshbuffer/src/styles/theme.mui.css';
import {
  AnalyticsPageProps
} from 'apps/freshbuffer/src/core/Analytics/types';
import { AppServicesProvider } from 'apps/freshbuffer/src/core/AppServices';
import { ErrorBoundary } from 'apps/freshbuffer/src/core/Errors/ErrorBoundary';
import { createEmotionCache } from 'apps/freshbuffer/src/styles/createEmotionCache';
import { getDeviceType } from 'apps/freshbuffer/src/utilities/UserAgent';
import { DeviceType } from 'apps/freshbuffer/src/utilities/UserAgent/types';
import { loadLocale } from '../locales';

const clientSideEmotionCache = createEmotionCache();


type PageProps = AnalyticsPageProps;

export interface AppProps extends NextAppProps<PageProps> {
  emotionCache?: EmotionCache;
  messages: Translations;
  pageProps: PageProps;
  deviceType: DeviceType;
}

const App = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
  deviceType,
  messages,
  router,
}: AppProps) => {
  const intl = React.useMemo(() => {
    const locale = Locale[router.locale as Locale] || Locale.nl;

    return createIntl({
      locale,
      messages,
    });
  }, [messages, router.locale]);

  const { T, locale } = intl;
  const dateAdapter = React.useMemo(
    () => createMuiDateLocalizationAdapter(),
    [],
  );
  const theme = React.useMemo(
    () => makeTheme(locale, deviceType),
    [deviceType, locale],
  );

  return (
    <ErrorBoundary T={T} locale={locale}>
      <React.Fragment>
        <Head>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width"
          />
        </Head>

        <IntlProvider value={intl}>
          <Head>
            <title>{T(CommonMessages.app_name)}</title>
            <meta
              name="apple-mobile-web-app-title"
              content={T(CommonMessages.app_name)}
            />
            <meta
              name="application-name"
              content={T(CommonMessages.app_name)}
            />
          </Head>

          <AppServicesProvider>
            {/* <ServiceWorkerSetup /> */}
              <CacheProvider value={emotionCache}>
                <ThemeProvider theme={theme}>
                  <StyledEngineProvider injectFirst>
                    <LocalizationProvider
                      dateAdapter={dateAdapter}
                      adapterLocale={locale}
                    >
                      <Component {...pageProps} />
                    </LocalizationProvider>
                  </StyledEngineProvider>
                </ThemeProvider>
              </CacheProvider>
          </AppServicesProvider>
        </IntlProvider>
      </React.Fragment>
    </ErrorBoundary>
  );
};

// opt-out of Automatic Static Optimization
App.getInitialProps = async (
  appContext: NextAppContext,
): Promise<
  Pick<
    AppProps,
    'messages' |  'pageProps'  | 'deviceType'
  >
> => {
  const { ctx } = appContext;
  const locale = Locale[ctx.locale as Locale] || Locale.nl;
  const route = ctx.pathname;
  const startTime = Date.now();

  console.log({
    message: `_app: processing route ${route} on ${
      ctx.req ? 'server' : 'browser'
    } ; started`,
    route,
    locale,
    query: ctx.query,
  });

  const deviceType: DeviceType = appContext.ctx.req
    ? getDeviceType(appContext.ctx.req.headers['user-agent'])
    : appContext.router.components[appContext.router.route]?.props
        ?.deviceType || 'desktop';

  let messages: Record<string, string> = {};


  console.log({
    message: `_app: processing route ${route} ; initialized auth state`,
    route,
    locale,
    query: ctx.query,
    duration: Date.now() - startTime,
  });

  const initialProps = await NextApp.getInitialProps(appContext);

  console.log({
    message: `_app: processing route ${route} ; loaded props`,
    route,
    locale,
    query: ctx.query,
    initialProps,
    duration: Date.now() - startTime,
  });

  const duration = Date.now() - startTime;

  if (ctx.req && duration > 800) {
    console.log({
      message: `_app: processing route ${route} ; loaded props with degraded performance`,
      route,
      locale,
      query: ctx.query,
      duration,
    });
  }

  if (!route.startsWith('/_') && route !== '/404' && route !== '/500') {

  }

  if (route !== '/_error') {
    messages = await loadLocale(locale);
  }

  console.log({
    message: `_app: processing route ${route} ; done`,
    route,
    locale,
    query: ctx.query,
    initialProps,
    duration: Date.now() - startTime,
  });

  return {
    messages,
    ...initialProps,
    deviceType,
  };
};

export function reportWebVitals(metric: NextWebVitalsMetric) {
  console.log({
    message: `Reporting web vital metric ${metric.name}`,
    ...metric,
  });
}

export default App;
