import getConfig from "next/config";

import { ApiClientBase } from "./ApiClientBase";

import { OtherApi } from "./Other";

const { publicRuntimeConfig } = getConfig();

const isServer = typeof global.window === "undefined";

export class ApiClient extends ApiClientBase {
  other: OtherApi;

  constructor() {
    super({
      freshbufferWebOrigin: publicRuntimeConfig.ORIGIN,
      freshbufferApiOrigin: isServer
        ? publicRuntimeConfig.FRESHBUFFER_API_ORIGIN
        : "/services/api",
    });

    this.other = new OtherApi(this);
  }
}
