import {
  createTranslate,
  createNumberFormat,
  createDateTimeFormat,
  Translations,
  TranslateFunc as BaseTranslateFunc,
} from "@freshbuffer/intl";

export enum Language {
  en = "en",
  nl = "nl",
}

export enum Locale {
  en = "en",
  nl = "nl",
}

export type TranslateFunc = BaseTranslateFunc & {
  errorMessage(fieldError: unknown, defaultMessage?: string): string;
};

export type IntlObject = {
  locale: Locale;
  messages: Translations;
} & ReturnType<typeof createTranslate> &
  ReturnType<typeof createNumberFormat> &
  ReturnType<typeof createDateTimeFormat> & {
    T: TranslateFunc;
  };
