import { Locale } from "apps/freshbuffer/src/core/Intl/types";

const localeCache: Partial<Record<Locale, Record<string, string>>> = {};

export async function loadLocale(
  locale: Locale
): Promise<Record<string, string>> {
  if (localeCache[locale]) {
    return localeCache[locale]!;
  }

  console.log(`loading locale ${locale}`);

  const data = await import(`./${locale}.json`);

  localeCache[locale] = data;

  return data;
}
