import { initAnalytics } from "apps/freshbuffer/src/core/Analytics/RudderStack/initAnalytics";
import { ApiClient } from "apps/freshbuffer/src/core/ApiClient";
import React from "react";
import { AppServicesContext } from "./AppServicesContext";
import { IAppServices } from "./types";

export const AppServicesProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const apiClient = React.useMemo(() => {
    const instance = new ApiClient();
    return instance;
  }, []);

  React.useEffect(() => {

    initAnalytics();
  }, []);

  const value = React.useMemo(
    () =>
      ({
        apiClient,
      } as IAppServices),
    [apiClient]
  );

  return (
    <AppServicesContext.Provider value={value}>
      {children}
    </AppServicesContext.Provider>
  );
};
