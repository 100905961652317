import { defineKey } from "@freshbuffer/intl";

export const app_name = defineKey("CommonMessages.app_name", "Freshbuffer");
export const operation_failure = defineKey(
  "CommonMessages.operation_failure",
  "Operation failed"
);
export const operation_success = defineKey(
  "CommonMessages.operation_success",
  "Operation succeeded"
);
export const general_failure = defineKey(
  "CommonMessages.general_failure",
  "An unknown failure occurred"
);
export const fetch_failure = defineKey(
  "CommonMessages.fetch_failure",
  "Data fetching failed"
);
