import React from 'react';

export function createRequiredContext<A>(displayName: string) {
  const Context = React.createContext<A | undefined>(undefined);

  Context.displayName = displayName;

  const Provider = Context.Provider as React.Provider<A>;

  const Consumer = ({
    children,
  }: {
    children: (args: A) => React.ReactNode;
  }) => (
    <Context.Consumer>
      {value => {
        if (value === undefined) {
          throw new Error(
            `Value is required in context ${Context.displayName}`,
          );
        }

        return children(value);
      }}
    </Context.Consumer>
  );

  Consumer.displayName = `${displayName}Consumer`;

  const useContext = () => {
    const value = React.useContext(Context);

    if (value === undefined) {
      throw new Error(`Value is required in context ${Context.displayName}`);
    }

    return value;
  };

  return {
    Provider,
    Consumer,
    useContext,
  };
}
