import { Locale } from "apps/freshbuffer/src/core/Intl/types";

export function setUserContextForErrorReporting(
  locale: Locale,
  anonymousId: string | null
) {
  console.log("Setting user context for error reporting", locale, anonymousId);
}

export function reportError(error: Error, captureContext?: any) {
  const { message, stack, ...rest } = error;
  console.log({
    message,
    stack,
    context: captureContext,
    ...rest,
  });
}
