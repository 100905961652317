import { UAParser } from 'ua-parser-js';
import { DeviceType, DeviceTypes } from './types';

export function getDeviceType(userAgent: string | undefined): DeviceType {
  const deviceType = new UAParser(userAgent).getResult().device.type;

  if (deviceType && DeviceTypes.some(i => i === deviceType)) {
    return deviceType as DeviceType;
  }

  return 'desktop';
}
