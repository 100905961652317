import { defineKey } from "@freshbuffer/intl";
import { GeneralErrorCode } from "./types";

export const GeneralErrorCodeMessages: Record<GeneralErrorCode, string> = {
  [GeneralErrorCode.UNKNOWN]: defineKey(
    "Errors.GeneralErrorCode.unknown",
    "Unknown error"
  ),
  [GeneralErrorCode.INVALID_REQUEST]: defineKey(
    "Errors.GeneralErrorCode.invalid_request",
    "Invalid request"
  ),
  [GeneralErrorCode.http_400]: defineKey(
    "Errors.GeneralErrorCode.http_400",
    "Invalid request"
  ),
  [GeneralErrorCode.http_401]: defineKey(
    "Errors.GeneralErrorCode.http_401",
    "Not authenticated"
  ),
  [GeneralErrorCode.http_403]: defineKey(
    "Errors.GeneralErrorCode.http_403",
    "Not authorized"
  ),
  [GeneralErrorCode.http_404]: defineKey(
    "Errors.GeneralErrorCode.http_404",
    "Not found"
  ),
  [GeneralErrorCode.http_500]: defineKey(
    "Errors.GeneralErrorCode.http_500",
    "Server failure"
  ),
  [GeneralErrorCode.http_501]: defineKey(
    "Errors.GeneralErrorCode.http_501",
    "Operation not implemented"
  ),
  [GeneralErrorCode.http_502]: defineKey(
    "Errors.GeneralErrorCode.http_502",
    "Server not accessible"
  ),
};
