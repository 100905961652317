import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import React from 'react';

export const NavLink = () => {};

export type NavLinkWrapProps = NextLinkProps & {
  children: React.ReactNode;
};

/** used for wrapping non-anchor component with Next.js routing */
// eslint-disable-next-line react/function-component-definition
NavLink.Wrap = function NavLinkWrap({ children, ...props }: NavLinkWrapProps) {
  return (
    <NextLink prefetch={false} legacyBehavior {...props}>
      {children}
    </NextLink>
  );
};

export type NavLinkAnchorProps = NextLinkProps & Omit<MuiLinkProps, 'href'>;

const NavLinkAnchor = React.forwardRef<HTMLAnchorElement, NavLinkAnchorProps>(
  (
    {
      children,

      href,

      // NextLinkProps
      as,
      replace,
      scroll,
      shallow,
      passHref,
      prefetch,
      locale,

      // MuiLinkProps
      ...muiLinkProps
    },
    ref,
  ) => (
    <NextLink
      href={href}
      legacyBehavior
      as={as}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref={passHref || true}
      prefetch={prefetch || false}
      locale={locale}
    >
      <MuiLink ref={ref} color="inherit" {...muiLinkProps}>
        {children}
      </MuiLink>
    </NextLink>
  ),
);

NavLinkAnchor.displayName = 'NavLink.Anchor';

/** used anchor-based Next.js routing (combination of Next.js and MUI links) */
NavLink.Anchor = NavLinkAnchor;

export type NavLinkNativeProps = MuiLinkProps;

const NavLinkNative = React.forwardRef<HTMLAnchorElement, NavLinkNativeProps>(
  (
    {
      children,
      href,
      // MuiLinkProps
      ...muiLinkProps
    },
    ref,
  ) => (
    <MuiLink ref={ref} color="inherit" {...muiLinkProps} href={href}>
      {children}
    </MuiLink>
  ),
);

NavLinkNative.displayName = 'NavLink.Native';

/** used for native browser behavior (non-Next.js link) */
NavLink.Native = NavLinkNative;

// @todo: add eslint rule enforcing usage of `NavLink.*` instead of `next/link` and `mui/link`
